define('ember-mobiledoc-editor/components/mobiledoc-markup-button/component', ['exports', 'ember', 'ember-mobiledoc-editor/components/mobiledoc-markup-button/template', 'ember-mobiledoc-editor/utils/titleize'], function (exports, _ember, _emberMobiledocEditorComponentsMobiledocMarkupButtonTemplate, _emberMobiledocEditorUtilsTitleize) {
  'use strict';

  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var defineProperty = _ember['default'].defineProperty;
  var Component = _ember['default'].Component;

  exports['default'] = Component.extend({
    tagName: 'button',
    layout: _emberMobiledocEditorComponentsMobiledocMarkupButtonTemplate['default'],
    attributeBindings: ['type', 'title'],
    classNameBindings: ['isActive:active'],
    type: 'button',
    init: function init() {
      this._super.apply(this, arguments);
      this._updateIsActiveCP();
    },
    onForDidChange: observer('for', function () {
      this._updateIsActiveCP();
    }),
    _updateIsActiveCP: function _updateIsActiveCP() {
      var forProperty = this.get('for');
      var fullPath = 'editor.activeMarkupTagNames.is' + (0, _emberMobiledocEditorUtilsTitleize['default'])(forProperty);
      var cp = computed(fullPath, function () {
        return this.get(fullPath);
      });
      defineProperty(this, 'isActive', cp);
    },
    click: function click() {
      var editor = this.get('editor');
      var forProperty = this.get('for');
      editor.toggleMarkup(forProperty);
    }
  });
});