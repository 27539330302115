define('ember-mobiledoc-editor/components/mobiledoc-editor/component', ['exports', 'ember', 'ember-mobiledoc-editor/components/mobiledoc-editor/template', 'mobiledoc-kit/editor/editor', 'mobiledoc-kit/renderers/mobiledoc', 'ember-mobiledoc-editor/utils/polyfill-assign'], function (exports, _ember, _emberMobiledocEditorComponentsMobiledocEditorTemplate, _mobiledocKitEditorEditor, _mobiledocKitRenderersMobiledoc, _emberMobiledocEditorUtilsPolyfillAssign) {
  'use strict';

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });
    } else {
      obj[key] = value;
    }return obj;
  }

  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var _Ember$String = _ember['default'].String;
  var capitalize = _Ember$String.capitalize;
  var camelize = _Ember$String.camelize;
  var ADD_CARD_HOOK = 'addComponent';
  exports.ADD_CARD_HOOK = ADD_CARD_HOOK;

  var REMOVE_CARD_HOOK = 'removeComponent';
  exports.REMOVE_CARD_HOOK = REMOVE_CARD_HOOK;

  var ADD_ATOM_HOOK = 'addAtomComponent';
  exports.ADD_ATOM_HOOK = ADD_ATOM_HOOK;

  var REMOVE_ATOM_HOOK = 'removeAtomComponent';
  exports.REMOVE_ATOM_HOOK = REMOVE_ATOM_HOOK;

  var WILL_CREATE_EDITOR_ACTION = 'will-create-editor';
  exports.WILL_CREATE_EDITOR_ACTION = WILL_CREATE_EDITOR_ACTION;

  var DID_CREATE_EDITOR_ACTION = 'did-create-editor';

  exports.DID_CREATE_EDITOR_ACTION = DID_CREATE_EDITOR_ACTION;

  var TESTING_EXPANDO_PROPERTY = '__mobiledoc_kit_editor';

  exports.TESTING_EXPANDO_PROPERTY = TESTING_EXPANDO_PROPERTY;

  var EDITOR_CARD_SUFFIX = '-editor';
  var EMPTY_MOBILEDOC = {
    version: _mobiledocKitRenderersMobiledoc.MOBILEDOC_VERSION,
    markups: [],
    atoms: [],
    cards: [],
    sections: []
  };

  function arrayToMap(array) {
    var map = Object.create(null);
    array.forEach(function (key) {
      if (key) {
        // skip undefined/falsy key values
        key = 'is' + capitalize(camelize(key));
        map[key] = true;
      }
    });
    return map;
  }

  exports['default'] = Component.extend({
    layout: _emberMobiledocEditorComponentsMobiledocEditorTemplate['default'],
    tagName: 'article',
    classNames: ['mobiledoc-editor'],

    placeholder: 'Write here...',
    spellcheck: true,
    autofocus: true,
    serializeVersion: _mobiledocKitRenderersMobiledoc.MOBILEDOC_VERSION,

    options: {},

    // merge in named options with the `options` property data-bag
    editorOptions: computed(function () {
      var options = this.get('options');

      return (0, _emberMobiledocEditorUtilsPolyfillAssign['default'])({
        placeholder: this.get('placeholder'),
        spellcheck: this.get('spellcheck'),
        autofocus: this.get('autofocus'),
        cards: this.get('cards') || [],
        atoms: this.get('atoms') || []
      }, options);
    }),

    init: function init() {
      this._super.apply(this, arguments);
      var mobiledoc = this.get('mobiledoc');
      if (!mobiledoc) {
        mobiledoc = EMPTY_MOBILEDOC;
        this.set('mobiledoc', mobiledoc);
      }
      this.set('componentCards', _ember['default'].A([]));
      this.set('componentAtoms', _ember['default'].A([]));
      this.set('linkOffsets', null);
      this.set('activeMarkupTagNames', {});
      this.set('activeSectionTagNames', {});
      this._startedRunLoop = false;
    },

    actions: {
      toggleMarkup: function toggleMarkup(markupTagName) {
        var editor = this.get('editor');
        editor.toggleMarkup(markupTagName);
      },

      toggleSection: function toggleSection(sectionTagName) {
        var editor = this.get('editor');
        editor.toggleSection(sectionTagName);
      },

      addCard: function addCard(cardName) {
        var payload = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

        this._addCard(cardName, payload);
      },

      addAtom: function addAtom(atomName) {
        var text = arguments.length <= 1 || arguments[1] === undefined ? '' : arguments[1];
        var payload = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

        this._addAtom(atomName, text, payload);
      },

      addCardInEditMode: function addCardInEditMode(cardName) {
        var payload = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

        var editMode = true;
        this._addCard(cardName, payload, editMode);
      },

      toggleLink: function toggleLink() {
        var editor = this.get('editor');
        if (!editor.hasCursor()) {
          return;
        }
        if (editor.hasActiveMarkup('a')) {
          editor.toggleMarkup('a');
        } else {
          this.set('linkOffsets', editor.range);
        }
      },

      completeLink: function completeLink(href) {
        var offsets = this.get('linkOffsets');
        this.set('linkOffsets', null);
        var editor = this.get('editor');
        editor.run(function (postEditor) {
          var markup = postEditor.builder.createMarkup('a', { href: href });
          postEditor.addMarkupToRange(offsets, markup);
        });
      },

      cancelLink: function cancelLink() {
        this.set('linkOffsets', null);
      }
    },

    editingContexts: computed(function () {
      return _ember['default'].A([]);
    }),

    willRender: function willRender() {
      var _editorOptions$cardOptions,
          _this = this;

      // Use a default mobiledoc. If there are no changes, then return
      // early.
      var mobiledoc = this.get('mobiledoc') || EMPTY_MOBILEDOC;
      if ((this._localMobiledoc && this._localMobiledoc === mobiledoc || this._upstreamMobiledoc && this._upstreamMobiledoc === mobiledoc) && this._lastIsEditingDisabled === this.get('isEditingDisabled')) {
        // No change to mobiledoc, no need to recreate the editor
        return;
      }
      this._lastIsEditingDisabled = this.get('isEditingDisabled');
      this._upstreamMobiledoc = mobiledoc;
      this._localMobiledoc = null;

      this.willCreateEditor();

      // Teardown any old editor that might be around.
      var editor = this.get('editor');
      if (editor) {
        editor.destroy();
      }

      // Create a new editor.
      var editorOptions = this.get('editorOptions');
      editorOptions.mobiledoc = mobiledoc;
      editorOptions.cardOptions = (_editorOptions$cardOptions = {}, _defineProperty(_editorOptions$cardOptions, ADD_CARD_HOOK, function (_ref) {
        var env = _ref.env;
        var options = _ref.options;
        var payload = _ref.payload;
        var isEditing = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

        var cardId = _ember['default'].uuid();
        var cardName = env.name;
        if (isEditing) {
          cardName = cardName + EDITOR_CARD_SUFFIX;
        }
        var destinationElementId = 'mobiledoc-editor-card-' + cardId;
        var element = document.createElement('div');
        element.id = destinationElementId;

        // The data must be copied to avoid sharing the reference
        payload = _ember['default'].copy(payload, true);

        var card = _ember['default'].Object.create({
          destinationElementId: destinationElementId,
          cardName: cardName,
          payload: payload,
          env: env,
          editor: editor,
          postModel: env.postModel
        });
        _ember['default'].run.schedule('afterRender', function () {
          _this.get('componentCards').pushObject(card);
        });
        return { card: card, element: element };
      }), _defineProperty(_editorOptions$cardOptions, ADD_ATOM_HOOK, function (_ref2) {
        var env = _ref2.env;
        var options = _ref2.options;
        var payload = _ref2.payload;
        var value = _ref2.value;

        var atomId = _ember['default'].uuid();
        var atomName = env.name;
        var destinationElementId = 'mobiledoc-editor-atom-' + atomId;
        var element = document.createElement('span');
        element.id = destinationElementId;

        // The data must be copied to avoid sharing the reference
        payload = _ember['default'].copy(payload, true);

        var atom = _ember['default'].Object.create({
          destinationElementId: destinationElementId,
          atomName: atomName,
          payload: payload,
          value: value,
          callbacks: env,
          editor: editor,
          postModel: env.postModel
        });
        _ember['default'].run.schedule('afterRender', function () {
          _this.get('componentAtoms').pushObject(atom);
        });
        return { atom: atom, element: element };
      }), _defineProperty(_editorOptions$cardOptions, REMOVE_CARD_HOOK, function (card) {
        _this.get('componentCards').removeObject(card);
      }), _defineProperty(_editorOptions$cardOptions, REMOVE_ATOM_HOOK, function (atom) {
        _this.get('componentAtoms').removeObject(atom);
      }), _editorOptions$cardOptions);
      editor = new _mobiledocKitEditorEditor['default'](editorOptions);
      editor.willRender(function () {
        // The editor's render/rerender will happen after this `editor.willRender`,
        // so we explicitly start a runloop here if there is none, so that the
        // add/remove card hooks happen inside a runloop.
        // When pasting text that gets turned into a card, for example,
        // the add card hook would run outside the runloop if we didn't begin a new
        // one now.
        if (!_ember['default'].run.currentRunLoop) {
          _this._startedRunLoop = true;
          _ember['default'].run.begin();
        }
      });
      editor.didRender(function () {
        // If we had explicitly started a run loop in `editor.willRender`,
        // we must explicitly end it here.
        if (_this._startedRunLoop) {
          _this._startedRunLoop = false;
          _ember['default'].run.end();
        }
      });
      editor.postDidChange(function () {
        _ember['default'].run.join(function () {
          _this.postDidChange(editor);
        });
      });
      editor.inputModeDidChange(function () {
        if (_this.isDestroyed) {
          return;
        }
        _ember['default'].run.join(function () {
          _this.inputModeDidChange(editor);
        });
      });
      if (this.get('isEditingDisabled')) {
        editor.disableEditing();
      }
      this.set('editor', editor);
      this.didCreateEditor(editor);
    },

    didRender: function didRender() {
      var editor = this.get('editor');
      if (!editor.hasRendered) {
        var editorElement = this.$('.mobiledoc-editor__editor')[0];
        this._isRenderingEditor = true;
        editor.render(editorElement);
        this._isRenderingEditor = false;
      }
      this._setExpandoProperty(editor);
    },

    willDestroyElement: function willDestroyElement() {
      var editor = this.get('editor');
      editor.destroy();
    },

    postDidChange: function postDidChange(editor) {
      var serializeVersion = this.get('serializeVersion');
      var updatedMobileDoc = editor.serialize(serializeVersion);
      this._localMobiledoc = updatedMobileDoc;
      this.sendAction('on-change', updatedMobileDoc);
    },

    inputModeDidChange: function inputModeDidChange(editor) {
      var _this2 = this;

      var markupTags = arrayToMap(editor.activeMarkups.map(function (m) {
        return m.tagName;
      }));
      // editor.activeSections are leaf sections.
      // Map parent section tag names (e.g. 'p', 'ul', 'ol') so that list buttons
      // are updated.
      var sectionParentTagNames = editor.activeSections.map(function (s) {
        return s.isNested ? s.parent.tagName : s.tagName;
      });
      var sectionTags = arrayToMap(sectionParentTagNames);

      // Avoid updating this component's properties synchronously while
      // rendering the editor (after rendering the component) because it
      // causes Ember to display deprecation warnings
      if (this._isRenderingEditor) {
        _ember['default'].run.schedule('afterRender', function () {
          _this2.set('activeMarkupTagNames', markupTags);
          _this2.set('activeSectionTagNames', sectionTags);
        });
      } else {
        this.set('activeMarkupTagNames', markupTags);
        this.set('activeSectionTagNames', sectionTags);
      }
    },

    willCreateEditor: function willCreateEditor() {
      this.sendAction(WILL_CREATE_EDITOR_ACTION);
    },

    didCreateEditor: function didCreateEditor(editor) {
      this.sendAction(DID_CREATE_EDITOR_ACTION, editor);
    },

    _addAtom: function _addAtom(atomName, text, payload) {
      var editor = this.get('editor');
      editor.insertAtom(atomName, text, payload);
    },

    _addCard: function _addCard(cardName, payload) {
      var editMode = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];

      var editor = this.get('editor');
      editor.insertCard(cardName, payload, editMode);
    },

    _setExpandoProperty: function _setExpandoProperty(editor) {
      // Store a reference to the editor for the acceptance test helpers
      if (this.element && _ember['default'].testing) {
        this.element[TESTING_EXPANDO_PROPERTY] = editor;
      }
    }
  });
});