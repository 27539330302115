define('ember-mobiledoc-editor/helpers/hash', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports.hash = hash;

  function hash(params, attributeHash) {
    return attributeHash;
  }

  exports['default'] = _ember['default'].Helper.helper(hash);
});