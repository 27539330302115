define("ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/utils/kv-reduce", ["exports"], function (exports) {
  "use strict";

  exports["default"] = kvReduce;

  /**
   * Reduces an array into an object of k/v pairs.
   *
   * @example
   * ['#href', '#foo', 'rel', 'nofollow'].reduce(kvReduce, {});
   * // returns { href: "#foo", rel: "nofollow" };
   */
  function kvReduce(obj, key, i, arr) {
    if (i % 2 === 0) {
      obj[key] = arr[i + 1];
    }
    return obj;
  }
});