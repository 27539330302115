define('ember-mobiledoc-editor/components/mobiledoc-link-button/component', ['exports', 'ember', 'ember-mobiledoc-editor/components/mobiledoc-link-button/template'], function (exports, _ember, _emberMobiledocEditorComponentsMobiledocLinkButtonTemplate) {
  'use strict';

  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;

  exports['default'] = Component.extend({
    tagName: 'button',
    layout: _emberMobiledocEditorComponentsMobiledocLinkButtonTemplate['default'],
    attributeBindings: ['type', 'title'],
    classNameBindings: ['isActive:active'],
    type: 'button',
    isActive: computed.bool('editor.activeMarkupTagNames.isA'),
    click: function click() {
      var editor = this.get('editor');
      editor.toggleLink();
    }
  });
});