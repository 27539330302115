define('ember-katex/components/katex-formula', ['exports', 'ember', 'katex', 'ember-katex/templates/components/katex-formula'], function (exports, _ember, _katex, _emberKatexTemplatesComponentsKatexFormula) {
  'use strict';

  /**
   * @class KatexFormula
   * @extends Ember.Component
   * @ember component
   **/

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var merge = _ember['default'].merge;
  var htmlSafe = _ember['default'].String.htmlSafe;
  exports['default'] = Component.extend({

    // ----- Arguments -----

    /**
     * A formula to render
     *
     * @property formula
     * @type String
     * @required
     * @ember argument
     **/
    formula: null,

    /**
     * Whether to crash on parse errors
     *
     * @property throwOnError
     * @type Boolean
     * @default false
     * @ember argument
     **/
    throwOnError: false,

    /**
     * A color which unsupported commands are rendered in
     *
     * @property errorColor
     * @type String
     * @default #cc0000
     * @ember argument
     **/
    errorColor: '#cc0000',

    /**
     * Whether to display the formula in inline (false) or block (true) form
     *
     * @property displayMode
     * @type Boolean
     * @default false
     * @ember argument
     **/
    displayMode: false,

    // ----- Overridden properties -----

    /**
     * @property classNames
     * @type Array
     **/
    classNames: ['katexFormula'],

    /**
     * @property layout
     * @final
     **/
    layout: _emberKatexTemplatesComponentsKatexFormula['default'],

    /**
     * @property tagName
     * @type String
     **/
    tagName: 'span',

    // ----- Computed properties -----

    /**
     * @proeprty options
     * @comupted throwOnError, errorColor, displayMode
     * @final
     **/
    options: computed('throwOnError', 'errorColor', 'displayMode', function () {
      var result = {};

      var throwOnError = this.get('throwOnError');

      if (throwOnError != null) {
        merge(result, { throwOnError: throwOnError });
      }

      var errorColor = this.get('errorColor');

      if (errorColor != null) {
        merge(result, { errorColor: errorColor });
      }

      var displayMode = this.get('displayMode');

      if (displayMode != null) {
        merge(result, { displayMode: displayMode });
      }

      return result;
    }),

    /**
     * HTML generated by KaTeX from `formula`
     *
     * @property renderedFormulaRaw
     * @type String
     * @final
     * @ember computed
     **/

    renderedFormulaRaw: computed('formula', function () {
      var formula = this.get('formula');

      if (!formula) {
        return;
      }

      var options = this.get('options');

      var result = undefined;

      try {
        result = (0, _katex.renderToString)(formula, options);
      } catch (e) {
        if (this.get('throwOnError') || !(e instanceof _katex.ParseError)) {
          throw e;
        }

        return formula;
      }

      return result;
    }),

    // ----- Computed property -----
    /**
     * `renderedFormulaRaw` wrapped into Ember.String.htmlSafe
     *
     * @property renderedFormulaHtmlSafe
     * @type String
     * @final
     * @ember computed
     **/

    renderedFormulaHtmlSafe: computed('renderedFormulaRaw', function () {
      var renderedFormulaRaw = this.get('renderedFormulaRaw');
      return htmlSafe(renderedFormulaRaw);
    })
  });
});