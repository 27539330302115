define('ember-mobiledoc-editor/utils/create-component-card', ['exports', 'ember-mobiledoc-editor/components/mobiledoc-editor/component'], function (exports, _emberMobiledocEditorComponentsMobiledocEditorComponent) {
  'use strict';

  exports['default'] = createComponentCard;

  var RENDER_TYPE = 'dom';function renderFallback(doc) {
    var element = doc.createElement('div');
    var text = doc.createTextNode('[placeholder for Ember component card]');
    element.appendChild(text);
    return element;
  }
  function createComponentCard(name) {
    var doc = arguments.length <= 1 || arguments[1] === undefined ? window.document : arguments[1];

    return {
      name: name,
      type: RENDER_TYPE,
      render: function render(cardArg) {
        var env = cardArg.env;
        var options = cardArg.options;

        if (!options[_emberMobiledocEditorComponentsMobiledocEditorComponent.ADD_CARD_HOOK]) {
          return renderFallback(doc);
        }

        var _options$ADD_CARD_HOOK = options[_emberMobiledocEditorComponentsMobiledocEditorComponent.ADD_CARD_HOOK](cardArg);

        var card = _options$ADD_CARD_HOOK.card;
        var element = _options$ADD_CARD_HOOK.element;
        var onTeardown = env.onTeardown;

        onTeardown(function () {
          return options[_emberMobiledocEditorComponentsMobiledocEditorComponent.REMOVE_CARD_HOOK](card);
        });

        return element;
      },
      edit: function edit(cardArg) {
        var env = cardArg.env;
        var options = cardArg.options;

        if (!options[_emberMobiledocEditorComponentsMobiledocEditorComponent.ADD_CARD_HOOK]) {
          return renderFallback(doc);
        }

        var isEditing = true;

        var _options$ADD_CARD_HOOK2 = options[_emberMobiledocEditorComponentsMobiledocEditorComponent.ADD_CARD_HOOK](cardArg, isEditing);

        var card = _options$ADD_CARD_HOOK2.card;
        var element = _options$ADD_CARD_HOOK2.element;
        var onTeardown = env.onTeardown;

        onTeardown(function () {
          return options[_emberMobiledocEditorComponentsMobiledocEditorComponent.REMOVE_CARD_HOOK](card);
        });

        return element;
      }
    };
  }
});