define('ember-mobiledoc-dom-renderer/utils/dom', ['exports'], function (exports) {
  'use strict';

  exports.getDOM = getDOM;

  function getDOM(context) {
    var renderer = context.renderer;

    if (renderer._dom) {
      // pre glimmer2
      return renderer._dom;
    } else if (renderer._env && renderer._env.getDOM) {
      // glimmer 2
      return renderer._env.getDOM();
    } else {
      throw new Error('Unable to get DOM helper');
    }
  }
});