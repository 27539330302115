define('ember-mobiledoc-editor/version', ['exports', 'mobiledoc-kit/version'], function (exports, _mobiledocKitVersion) {
  'use strict';

  var EDITOR_VERSION = '0.4.3';
  var VERSION = {
    EDITOR_VERSION: EDITOR_VERSION,
    MOBILEDOC_KIT_VERSION: _mobiledocKitVersion['default']
  };

  exports['default'] = VERSION;
});