define('ember-mobiledoc-editor/components/mobiledoc-toolbar/component', ['exports', 'ember', 'ember-mobiledoc-editor/components/mobiledoc-toolbar/template'], function (exports, _ember, _emberMobiledocEditorComponentsMobiledocToolbarTemplate) {
  'use strict';

  var Component = _ember['default'].Component;

  exports['default'] = Component.extend({
    layout: _emberMobiledocEditorComponentsMobiledocToolbarTemplate['default'],
    tagName: 'ul',
    classNames: ['mobiledoc-toolbar']
  });
});