define('ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/index', ['exports', 'ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/renderer-factory', 'ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/utils/render-type'], function (exports, _emberMobiledocDomRendererMobiledocDomRendererRendererFactory, _emberMobiledocDomRendererMobiledocDomRendererUtilsRenderType) {
  'use strict';

  exports.registerGlobal = registerGlobal;
  exports.RENDER_TYPE = _emberMobiledocDomRendererMobiledocDomRendererUtilsRenderType['default'];

  function registerGlobal(window) {
    window.MobiledocDOMRenderer = _emberMobiledocDomRendererMobiledocDomRendererRendererFactory['default'];
  }

  exports['default'] = _emberMobiledocDomRendererMobiledocDomRendererRendererFactory['default'];
});