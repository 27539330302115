define('ember-mobiledoc-editor/components/mobiledoc-link-prompt/component', ['exports', 'ember', 'ember-mobiledoc-editor/components/mobiledoc-link-prompt/template'], function (exports, _ember, _emberMobiledocEditorComponentsMobiledocLinkPromptTemplate) {
  'use strict';

  var Component = _ember['default'].Component;

  exports['default'] = Component.extend({
    layout: _emberMobiledocEditorComponentsMobiledocLinkPromptTemplate['default'],

    didInsertElement: function didInsertElement() {
      this.$('input').focus();
    }
  });
});