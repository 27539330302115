define('ember-mobiledoc-dom-renderer/components/render-mobiledoc', ['exports', 'ember', 'ember-mobiledoc-dom-renderer', 'ember-mobiledoc-dom-renderer/templates/components/render-mobiledoc', 'ember-mobiledoc-dom-renderer/utils/dom'], function (exports, _ember, _emberMobiledocDomRenderer, _emberMobiledocDomRendererTemplatesComponentsRenderMobiledoc, _emberMobiledocDomRendererUtilsDom) {
  'use strict';

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });
    } else {
      obj[key] = value;
    }return obj;
  }

  var assert = _ember['default'].assert;
  var computed = _ember['default'].computed;
  var join = _ember['default'].run.join;
  var uuid = _ember['default'].uuid;

  var ADD_CARD_HOOK = 'addComponentCard';
  var REMOVE_CARD_HOOK = 'removeComponentCard';
  var ADD_ATOM_HOOK = 'addComponentAtom';
  var REMOVE_ATOM_HOOK = 'removeComponentAtom';
  var CARD_TAG_NAME = 'div';
  var ATOM_TAG_NAME = 'span';
  var UUID_PREFIX = '__rendered-mobiledoc-entity-';
  var CARD_ELEMENT_CLASS = '__rendered-mobiledoc-card';
  exports.CARD_ELEMENT_CLASS = CARD_ELEMENT_CLASS;

  var ATOM_ELEMENT_CLASS = '__rendered-mobiledoc-atom';

  exports.ATOM_ELEMENT_CLASS = ATOM_ELEMENT_CLASS;

  var CARD_HOOKS = {
    ADD: ADD_CARD_HOOK,
    REMOVE: REMOVE_CARD_HOOK
  };

  var ATOM_HOOKS = {
    ADD: ADD_ATOM_HOOK,
    REMOVE: REMOVE_ATOM_HOOK
  };

  function rendererFor(type) {
    var hookNames = undefined;

    if (type === 'card') {
      hookNames = CARD_HOOKS;
    } else if (type === 'atom') {
      hookNames = ATOM_HOOKS;
    }

    return function (_ref2) {
      var env = _ref2.env;
      var options = _ref2.options;
      var onTeardown = env.onTeardown;

      var addHook = options[hookNames.ADD];
      var removeHook = options[hookNames.REMOVE];

      var _addHook = addHook.apply(undefined, arguments);

      var entity = _addHook.entity;
      var element = _addHook.element;

      onTeardown(function () {
        return removeHook(entity);
      });

      return element;
    };
  }

  function createComponentCard(name) {
    return {
      name: name,
      type: _emberMobiledocDomRenderer.RENDER_TYPE,
      render: rendererFor('card')
    };
  }

  function createComponentAtom(name) {
    return {
      name: name,
      type: _emberMobiledocDomRenderer.RENDER_TYPE,
      render: rendererFor('atom')
    };
  }

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberMobiledocDomRendererTemplatesComponentsRenderMobiledoc['default'],

    didReceiveAttrs: function didReceiveAttrs() {
      var mobiledoc = this.get('mobiledoc');
      assert('Must pass mobiledoc to render-mobiledoc component', !!mobiledoc);

      if (this._teardownRender) {
        this._teardownRender();
        this._teardownRender = null;
      }
      this._renderMobiledoc();
    },

    // pass in an array of card names that the mobiledoc may have. These
    // map to component names using `cardNameToComponentName`
    cardNames: [],

    // pass in an array of atom names that the mobiledoc may have. These
    // map to component names using `atomNameToComponentName`
    atomNames: [],

    _mdcCards: computed('cardNames', function () {
      return this.get('cardNames').map(function (name) {
        return createComponentCard(name);
      });
    }),

    _mdcAtoms: computed('atomNames', function () {
      return this.get('atomNames').map(function (name) {
        return createComponentAtom(name);
      });
    }),

    _renderMobiledoc: function _renderMobiledoc() {
      var _this = this;

      var domHelper = (0, _emberMobiledocDomRendererUtilsDom.getDOM)(this);
      var dom = domHelper.document;

      var mobiledoc = this.get('mobiledoc');

      var options = {
        dom: dom,
        cards: this.get('_mdcCards'),
        atoms: this.get('_mdcAtoms'),
        cardOptions: this.get('_cardOptions')
      };
      ['mobiledoc', 'sectionElementRenderer', 'markupElementRenderer', 'unknownCardHandler', 'unknownAtomHandler'].forEach(function (option) {
        var value = _this.get(option);
        if (value) {
          options[option] = value;
        }
      });

      var renderer = new _emberMobiledocDomRenderer['default'](options);

      var _renderer$render = renderer.render(mobiledoc);

      var result = _renderer$render.result;
      var teardown = _renderer$render.teardown;

      // result is a document fragment, and glimmer2 errors when cleaning it up.
      // We must append the document fragment to a static wrapper.
      // Related: https://github.com/tildeio/glimmer/pull/331 and
      //          https://github.com/yapplabs/ember-wormhole/issues/66#issuecomment-246207622
      var wrapper = this._createElement(dom, 'div');
      wrapper.appendChild(result);

      this.set('renderedMobiledoc', wrapper);
      this._teardownRender = teardown;
    },

    _cardOptions: computed(function () {
      var _ref,
          _this2 = this;

      return _ref = {}, _defineProperty(_ref, ADD_CARD_HOOK, function (_ref3) {
        var env = _ref3.env;
        var options = _ref3.options;
        var payload = _ref3.payload;
        var cardName = env.name;
        var dom = env.dom;

        var classNames = [CARD_ELEMENT_CLASS, CARD_ELEMENT_CLASS + '-' + cardName];
        var element = _this2._createElement(dom, CARD_TAG_NAME, classNames);
        var componentName = _this2.cardNameToComponentName(cardName);

        var card = {
          componentName: componentName,
          destinationElementId: element.getAttribute('id'),
          payload: payload
        };
        _this2.addCard(card);
        return { entity: card, element: element };
      }), _defineProperty(_ref, ADD_ATOM_HOOK, function (_ref4) {
        var env = _ref4.env;
        var options = _ref4.options;
        var value = _ref4.value;
        var payload = _ref4.payload;
        var atomName = env.name;
        var dom = env.dom;

        var classNames = [ATOM_ELEMENT_CLASS, ATOM_ELEMENT_CLASS + '-' + atomName];
        var element = _this2._createElement(dom, ATOM_TAG_NAME, classNames);
        var componentName = _this2.atomNameToComponentName(atomName);

        var atom = {
          componentName: componentName,
          destinationElementId: element.getAttribute('id'),
          payload: payload,
          value: value
        };
        _this2.addAtom(atom);
        return { entity: atom, element: element };
      }), _defineProperty(_ref, REMOVE_CARD_HOOK, function (card) {
        return _this2.removeCard(card);
      }), _defineProperty(_ref, REMOVE_ATOM_HOOK, function (atom) {
        return _this2.removeAtom(atom);
      }), _ref;
    }),

    willDestroyElement: function willDestroyElement() {
      if (this._teardownRender) {
        this._teardownRender();
      }
      return this._super.apply(this, arguments);
    },

    // override in subclass to change the mapping of card name -> component name
    cardNameToComponentName: function cardNameToComponentName(name) {
      return name;
    },

    // override in subclass to change the mapping of atom name -> component name
    atomNameToComponentName: function atomNameToComponentName(name) {
      return name;
    },

    // @private

    _componentCards: computed(function () {
      return _ember['default'].A();
    }),

    _componentAtoms: computed(function () {
      return _ember['default'].A();
    }),

    addCard: function addCard(card) {
      this.get('_componentCards').pushObject(card);
    },

    removeCard: function removeCard(card) {
      var _this3 = this;

      join(function () {
        _this3.get('_componentCards').removeObject(card);
      });
    },

    addAtom: function addAtom(atom) {
      this.get('_componentAtoms').pushObject(atom);
    },

    removeAtom: function removeAtom(atom) {
      var _this4 = this;

      join(function () {
        _this4.get('_componentAtoms').removeObject(atom);
      });
    },

    generateUuid: function generateUuid() {
      return '' + UUID_PREFIX + uuid();
    },

    _createElement: function _createElement(dom, tagName) {
      var classNames = arguments.length <= 2 || arguments[2] === undefined ? [] : arguments[2];

      var el = dom.createElement(tagName);
      el.setAttribute('id', this.generateUuid());
      el.setAttribute('class', classNames.join(' '));
      return el;
    }
  });
});