define('ember-mobiledoc-editor/utils/titleize', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var _Ember$String = _ember['default'].String;
  var capitalize = _Ember$String.capitalize;
  var camelize = _Ember$String.camelize;

  exports['default'] = function (string) {
    return capitalize(camelize(string));
  };
});