define('ember-cli-showdown/components/markdown-to-html', ['exports', 'ember'], function (exports, _ember) {
  /* global showdown */
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    init: function init() {
      this._super();

      this.converter = new showdown.Converter({
        extensions: this.get("extensions") || []
      });
    },

    html: _ember['default'].computed('markdown', function () {
      var showdownOptions = this.getProperties('omitExtraWLInCodeBlocks', 'noHeaderId', 'prefixHeaderId', 'parseImgDimensions', 'headerLevelStart', 'simplifiedAutoLink', 'literalMidWordUnderscores', 'strikethrough', 'tables', 'tablesHeaderId', 'ghCodeBlocks', 'tasklists', 'smoothLivePreview');

      for (var option in showdownOptions) {
        if (showdownOptions.hasOwnProperty(option)) {
          this.converter.setOption(option, showdownOptions[option]);
        }
      }

      var source = this.get('markdown') || '';
      return new _ember['default'].Handlebars.SafeString(this.converter.makeHtml(source));
    })
  });
});