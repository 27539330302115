define('ember-mobiledoc-editor/utils/create-component-atom', ['exports', 'ember-mobiledoc-editor/components/mobiledoc-editor/component'], function (exports, _emberMobiledocEditorComponentsMobiledocEditorComponent) {
  'use strict';

  exports['default'] = createComponentAtom;

  var RENDER_TYPE = 'dom';function renderFallback(doc) {
    var element = document.createElement('span');
    var text = doc.createTextNode('[placeholder for Ember atom]');
    element.appendChild(text);
    return element;
  }
  function createComponentAtom(name) {
    var doc = arguments.length <= 1 || arguments[1] === undefined ? window.document : arguments[1];

    return {
      name: name,
      type: RENDER_TYPE,
      render: function render(atomArg) {
        var env = atomArg.env;
        var options = atomArg.options;

        if (!options[_emberMobiledocEditorComponentsMobiledocEditorComponent.ADD_ATOM_HOOK]) {
          return renderFallback(doc);
        }

        var _options$ADD_ATOM_HOOK = options[_emberMobiledocEditorComponentsMobiledocEditorComponent.ADD_ATOM_HOOK](atomArg);

        var atom = _options$ADD_ATOM_HOOK.atom;
        var element = _options$ADD_ATOM_HOOK.element;
        var onTeardown = env.onTeardown;

        onTeardown(function () {
          return options[_emberMobiledocEditorComponentsMobiledocEditorComponent.REMOVE_ATOM_HOOK](atom);
        });

        return element;
      }
    };
  }
});