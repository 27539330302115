define('ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/renderer-factory', ['exports', 'ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/renderers/0-2', 'ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/renderers/0-3', 'ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/utils/render-type'], function (exports, _emberMobiledocDomRendererMobiledocDomRendererRenderers02, _emberMobiledocDomRendererMobiledocDomRendererRenderers03, _emberMobiledocDomRendererMobiledocDomRendererUtilsRenderType) {
  'use strict';

  var _createClass = (function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];descriptor.enumerable = descriptor.enumerable || false;descriptor.configurable = true;if ('value' in descriptor) descriptor.writable = true;Object.defineProperty(target, descriptor.key, descriptor);
      }
    }return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);if (staticProps) defineProperties(Constructor, staticProps);return Constructor;
    };
  })();

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError('Cannot call a class as a function');
    }
  }

  /**
   * runtime DOM renderer
   * renders a mobiledoc to DOM
   *
   * input: mobiledoc
   * output: DOM
   */

  function validateCards(cards) {
    if (!Array.isArray(cards)) {
      throw new Error('`cards` must be passed as an array');
    }
    for (var i = 0; i < cards.length; i++) {
      var card = cards[i];
      if (card.type !== _emberMobiledocDomRendererMobiledocDomRendererUtilsRenderType['default']) {
        throw new Error('Card "' + card.name + '" must be of type "' + _emberMobiledocDomRendererMobiledocDomRendererUtilsRenderType['default'] + '", was "' + card.type + '"');
      }
      if (!card.render) {
        throw new Error('Card "' + card.name + '" must define `render`');
      }
    }
  }

  function validateAtoms(atoms) {
    if (!Array.isArray(atoms)) {
      throw new Error('`atoms` must be passed as an array');
    }
    for (var i = 0; i < atoms.length; i++) {
      var atom = atoms[i];
      if (atom.type !== _emberMobiledocDomRendererMobiledocDomRendererUtilsRenderType['default']) {
        throw new Error('Atom "' + atom.name + '" must be type "' + _emberMobiledocDomRendererMobiledocDomRendererUtilsRenderType['default'] + '", was "' + atom.type + '"');
      }
      if (!atom.render) {
        throw new Error('Atom "' + atom.name + '" must define `render`');
      }
    }
  }

  var RendererFactory = (function () {
    function RendererFactory() {
      var _ref = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      var cards = _ref.cards;
      var atoms = _ref.atoms;
      var cardOptions = _ref.cardOptions;
      var unknownCardHandler = _ref.unknownCardHandler;
      var unknownAtomHandler = _ref.unknownAtomHandler;
      var markupElementRenderer = _ref.markupElementRenderer;
      var sectionElementRenderer = _ref.sectionElementRenderer;
      var dom = _ref.dom;

      _classCallCheck(this, RendererFactory);

      cards = cards || [];
      validateCards(cards);
      atoms = atoms || [];
      validateAtoms(atoms);
      cardOptions = cardOptions || {};

      if (!dom) {
        if (typeof window === 'undefined') {
          throw new Error('A `dom` option must be provided to the renderer when running without window.document');
        }
        dom = window.document;
      }

      this.options = {
        cards: cards,
        atoms: atoms,
        cardOptions: cardOptions,
        unknownCardHandler: unknownCardHandler,
        unknownAtomHandler: unknownAtomHandler,
        markupElementRenderer: markupElementRenderer,
        sectionElementRenderer: sectionElementRenderer,
        dom: dom
      };
    }

    _createClass(RendererFactory, [{
      key: 'render',
      value: function render(mobiledoc) {
        var version = mobiledoc.version;

        switch (version) {
          case _emberMobiledocDomRendererMobiledocDomRendererRenderers02.MOBILEDOC_VERSION:
          case undefined:
          case null:
            return new _emberMobiledocDomRendererMobiledocDomRendererRenderers02['default'](mobiledoc, this.options).render();
          case _emberMobiledocDomRendererMobiledocDomRendererRenderers03.MOBILEDOC_VERSION_0_3_0:
          case _emberMobiledocDomRendererMobiledocDomRendererRenderers03.MOBILEDOC_VERSION_0_3_1:
            return new _emberMobiledocDomRendererMobiledocDomRendererRenderers03['default'](mobiledoc, this.options).render();
          default:
            throw new Error('Unexpected Mobiledoc version "' + version + '"');
        }
      }
    }]);

    return RendererFactory;
  })();

  exports['default'] = RendererFactory;
});