define("ember-mobiledoc-editor/components/mobiledoc-editor/template", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 18,
                "column": 2
              },
              "end": {
                "line": 22,
                "column": 2
              }
            },
            "moduleName": "modules/ember-mobiledoc-editor/components/mobiledoc-editor/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "mobiledoc-link-prompt", [], ["on-submit", ["subexpr", "action", ["completeLink"], [], ["loc", [null, [20, 18], [20, 41]]], 0, 0], "on-cancel", ["subexpr", "action", ["cancelLink"], [], ["loc", [null, [21, 18], [21, 39]]], 0, 0]], ["loc", [null, [19, 4], [21, 41]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 0
            },
            "end": {
              "line": 23,
              "column": 0
            }
          },
          "moduleName": "modules/ember-mobiledoc-editor/components/mobiledoc-editor/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "tether-to-selection", [], [], 0, null, ["loc", [null, [18, 2], [22, 26]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 26,
                "column": 2
              },
              "end": {
                "line": 40,
                "column": 2
              }
            },
            "moduleName": "modules/ember-mobiledoc-editor/components/mobiledoc-editor/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "component", [["get", "card.cardName", ["loc", [null, [29, 16], [29, 29]]], 0, 0, 0, 0]], ["editor", ["subexpr", "@mut", [["get", "editor", ["loc", [null, [30, 15], [30, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "postModel", ["subexpr", "@mut", [["get", "card.postModel", ["loc", [null, [31, 18], [31, 32]]], 0, 0, 0, 0]], [], [], 0, 0], "cardName", ["subexpr", "@mut", [["get", "card.cardName", ["loc", [null, [32, 17], [32, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "payload", ["subexpr", "@mut", [["get", "card.payload", ["loc", [null, [33, 16], [33, 28]]], 0, 0, 0, 0]], [], [], 0, 0], "data", ["subexpr", "@mut", [["get", "card.payload", ["loc", [null, [34, 13], [34, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "env", ["subexpr", "@mut", [["get", "card.env", ["loc", [null, [35, 12], [35, 20]]], 0, 0, 0, 0]], [], [], 0, 0], "editCard", ["subexpr", "action", [["get", "card.env.edit", ["loc", [null, [36, 25], [36, 38]]], 0, 0, 0, 0]], [], ["loc", [null, [36, 17], [36, 39]]], 0, 0], "saveCard", ["subexpr", "action", [["get", "card.env.save", ["loc", [null, [37, 25], [37, 38]]], 0, 0, 0, 0]], [], ["loc", [null, [37, 17], [37, 39]]], 0, 0], "cancelCard", ["subexpr", "action", [["get", "card.env.cancel", ["loc", [null, [38, 27], [38, 42]]], 0, 0, 0, 0]], [], ["loc", [null, [38, 19], [38, 43]]], 0, 0], "removeCard", ["subexpr", "action", [["get", "card.env.remove", ["loc", [null, [39, 27], [39, 42]]], 0, 0, 0, 0]], [], ["loc", [null, [39, 19], [39, 43]]], 0, 0]], ["loc", [null, [29, 4], [39, 45]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 25,
              "column": 0
            },
            "end": {
              "line": 41,
              "column": 0
            }
          },
          "moduleName": "modules/ember-mobiledoc-editor/components/mobiledoc-editor/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "ember-wormhole", [], ["to", ["subexpr", "@mut", [["get", "card.destinationElementId", ["loc", [null, [26, 23], [26, 48]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [26, 2], [40, 21]]]]],
        locals: ["card"],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 44,
                "column": 2
              },
              "end": {
                "line": 53,
                "column": 2
              }
            },
            "moduleName": "modules/ember-mobiledoc-editor/components/mobiledoc-editor/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "component", [["get", "atom.atomName", ["loc", [null, [45, 16], [45, 29]]], 0, 0, 0, 0]], ["editor", ["subexpr", "@mut", [["get", "editor", ["loc", [null, [46, 15], [46, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "postModel", ["subexpr", "@mut", [["get", "atom.postModel", ["loc", [null, [47, 18], [47, 32]]], 0, 0, 0, 0]], [], [], 0, 0], "atomName", ["subexpr", "@mut", [["get", "atom.atomName", ["loc", [null, [48, 17], [48, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "payload", ["subexpr", "@mut", [["get", "atom.payload", ["loc", [null, [49, 16], [49, 28]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "atom.value", ["loc", [null, [50, 14], [50, 24]]], 0, 0, 0, 0]], [], [], 0, 0], "saveAtom", ["subexpr", "action", [["get", "atom.callbacks.save", ["loc", [null, [51, 25], [51, 44]]], 0, 0, 0, 0]], [], ["loc", [null, [51, 17], [51, 45]]], 0, 0]], ["loc", [null, [45, 4], [52, 10]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 43,
              "column": 0
            },
            "end": {
              "line": 54,
              "column": 0
            }
          },
          "moduleName": "modules/ember-mobiledoc-editor/components/mobiledoc-editor/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "ember-wormhole", [], ["to", ["subexpr", "@mut", [["get", "atom.destinationElementId", ["loc", [null, [44, 23], [44, 48]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [44, 2], [53, 21]]]]],
        locals: ["atom"],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 55,
            "column": 0
          }
        },
        "moduleName": "modules/ember-mobiledoc-editor/components/mobiledoc-editor/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "mobiledoc-editor__editor-wrapper");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "mobiledoc-editor__editor");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 6, 6, contextualElement);
        morphs[3] = dom.createMorphAt(fragment, 8, 8, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "yield", [["subexpr", "hash", [], ["editor", ["get", "editor", ["loc", [null, [2, 9], [2, 15]]], 0, 0, 0, 0], "activeSectionTagNames", ["get", "activeSectionTagNames", ["loc", [null, [3, 24], [3, 45]]], 0, 0, 0, 0], "activeMarkupTagNames", ["get", "activeMarkupTagNames", ["loc", [null, [4, 23], [4, 43]]], 0, 0, 0, 0], "toggleMarkup", ["subexpr", "action", ["toggleMarkup"], [], ["loc", [null, [5, 15], [5, 38]]], 0, 0], "toggleLink", ["subexpr", "action", ["toggleLink"], [], ["loc", [null, [6, 13], [6, 34]]], 0, 0], "addCard", ["subexpr", "action", ["addCard"], [], ["loc", [null, [7, 10], [7, 28]]], 0, 0], "addAtom", ["subexpr", "action", ["addAtom"], [], ["loc", [null, [8, 10], [8, 28]]], 0, 0], "addCardInEditMode", ["subexpr", "action", ["addCardInEditMode"], [], ["loc", [null, [9, 20], [9, 48]]], 0, 0], "toggleSection", ["subexpr", "action", ["toggleSection"], [], ["loc", [null, [10, 16], [10, 40]]], 0, 0]], ["loc", [null, [1, 8], [11, 1]]], 0, 0]], [], ["loc", [null, [1, 0], [11, 3]]], 0, 0], ["block", "if", [["get", "linkOffsets", ["loc", [null, [17, 6], [17, 17]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [17, 0], [23, 7]]]], ["block", "each", [["get", "componentCards", ["loc", [null, [25, 8], [25, 22]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [25, 0], [41, 9]]]], ["block", "each", [["get", "componentAtoms", ["loc", [null, [43, 8], [43, 22]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [43, 0], [54, 9]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});