define('ember-mobiledoc-editor/components/tether-to-selection/component', ['exports', 'ember', 'ember-mobiledoc-editor/components/tether-to-selection/template'], function (exports, _ember, _emberMobiledocEditorComponentsTetherToSelectionTemplate) {
  'use strict';

  var Component = _ember['default'].Component;

  var LEFT_PADDING = 0;
  var TOP_PADDING = 10;

  exports['default'] = Component.extend({
    layout: _emberMobiledocEditorComponentsTetherToSelectionTemplate['default'],
    classNames: ['mobiledoc-selection-tether'],
    left: 0,
    top: 0,

    willInsertElement: function willInsertElement() {
      var selection = window.getSelection();
      var range = selection && selection.rangeCount && selection.getRangeAt(0);

      _ember['default'].assert('Should not render {{#tether-to-selection}} when there is no selection', !!range);

      if (range) {
        var rect = range.getBoundingClientRect();
        this.set('left', rect.left);
        this.set('top', rect.top);
      }
    },

    didInsertElement: function didInsertElement() {
      var _this = this;

      _ember['default'].run.schedule('afterRender', function () {
        var myHeight = _this.$().height();
        var left = _this.get('left') - LEFT_PADDING;
        var top = _this.get('top') - TOP_PADDING - myHeight;

        _this.$().css({
          position: 'fixed',
          left: left + 'px',
          top: top + 'px'
        });
      });
    }
  });
});