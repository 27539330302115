define('ember-mobiledoc-text-renderer/index', ['exports', 'ember-mobiledoc-text-renderer/renderer-factory', 'ember-mobiledoc-text-renderer/utils/render-type'], function (exports, _emberMobiledocTextRendererRendererFactory, _emberMobiledocTextRendererUtilsRenderType) {
  'use strict';

  exports.registerGlobal = registerGlobal;

  function registerGlobal(window) {
    window.MobiledocTextRenderer = _emberMobiledocTextRendererRendererFactory['default'];
  }

  exports.RENDER_TYPE = _emberMobiledocTextRendererUtilsRenderType['default'];
  exports['default'] = _emberMobiledocTextRendererRendererFactory['default'];
});