define('ember-katex/components/katex-html', ['exports', 'ember', 'katex', 'ember-katex/templates/components/katex-html'], function (exports, _ember, _katex, _emberKatexTemplatesComponentsKatexHtml) {
  'use strict';

  /**
   * @class KatexHTML
   * @extends Ember.Component
   * @ember component
   **/

  var $ = _ember['default'].$;
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var merge = _ember['default'].merge;
  var on = _ember['default'].on;
  var htmlSafe = _ember['default'].String.htmlSafe;
  exports['default'] = Component.extend({

    // ----- Arguments -----

    /**
     * @property catchErrors
     * @type Boolean
     * @default true
     * @ember argument
     **/
    catchErrors: true,

    /**
     * @property delimiters
     * @type Array
     * @optional
     * @default undefined
     * @ember argument
     **/
    delimiters: undefined,

    /**
     * @property ignoredTags
     * @type Array
     * @optional
     * @default undefined
     * @ember argument
     **/
    ignoredTags: undefined,

    // ----- Overridden properties -----

    /**
     * @property layout
     * @final
     **/
    layout: _emberKatexTemplatesComponentsKatexHtml['default'],

    /**
     * @property classNames
     * @type Array
     **/
    classNames: ['katexHtml'],

    // ----- Computed properties -----

    /**
     * @proeprty options
     * @comupted delimiters, ignoredTags
     * @final
     **/
    options: computed('delimiters', 'ignoredTags', function () {
      var result = {};

      var delimiters = this.get('delimiters');

      if (delimiters != null) {
        merge(result, { delimiters: delimiters });
      }

      var ignoredTags = this.get('ignoredTags');

      if (ignoredTags != null) {
        merge(result, { ignoredTags: ignoredTags });
      }

      return result;
    }),

    /**
     * @proeprty result
     *
     * An htmlSafe string containing HTML with formulas rendered.
     *
     * @comupted safeHtml, options
     * @final
     **/
    result: computed('safeHtml', 'options', function () {
      //ToDo: error if `safeHtml` string is not html safe
      var $element = $('<div>' + this.get('safeHtml') + '</div>');
      (0, _katex.renderMathInElement)($element[0], this.get('options'));
      return htmlSafe($element.prop('outerHTML'));
    }),

    // ----- Events -----
    /**
     * @method renderMathInElement
     * @on didInsertElement
     *
     **/
    renderMathInElement: on('didInsertElement', function () {
      if (this.get('safeHtml') != null) {
        return;
      }

      var element = this.get('element');
      var options = this.get('options');
      (0, _katex.renderMathInElement)(element, options);
    })
  });
});